@font-face {
  font-family: Segoe UI;
  src: local(Segoe UI Light), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2") format("woff2"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff") format("woff"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Segoe UI;
  src: local(Segoe UI Semilight), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2") format("woff2"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff") format("woff"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Segoe UI;
  src: local(Segoe UI), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2") format("woff2"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff") format("woff"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Segoe UI;
  src: local(Segoe UI Semibold), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2") format("woff2"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff") format("woff"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Segoe UI;
  src: local(Segoe UI Bold), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2") format("woff2"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff") format("woff"), url("https://c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

.G774PW_content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 40px 1fr 40px;
  display: grid;
}

.G774PW_botMsg, .G774PW_userMsg {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.G774PW_botMsg {
  align-items: flex-start;
}

.G774PW_userMsg {
  align-items: flex-end;
}

.BCzNwa_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
}

.BCzNwa_container > * {
  width: 100%;
  max-width: 500px;
}

.h1Bu9q_container {
  border: solid 2px var(--colorBrandForeground1);
  cursor: pointer;
  color: var(--colorBrandForegroundLink);
  box-shadow: 0 2px 4px var(--colorNeutralShadowAmbient);
  border-radius: 5px;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  display: flex;
}

.h1Bu9q_container:hover {
  color: var(--colorBrandForegroundLinkHover);
  border-color: var(--colorBrandForeground1Hover);
}

.h1Bu9q_container:hover:active {
  color: var(--colorBrandForegroundLinkPressed);
  border-color: var(--colorBrandForeground1Pressed);
}

/*# sourceMappingURL=index.6036ceb4.css.map */
